import Vue from 'vue'
import _ from 'lodash'

export default {
  SET_SCHEDULE (state, payload) {
    state.schedule = Object.assign({}, state.schedule, payload) //precisa ser assim para manter a reatividade
  },
  UPDATE_SCHEDULE (state, payload) {
    const index1 = state.studentSchedules.findIndex(o => o.id === payload.id)
    const index2 = state.employeeSchedules.findIndex(o => o.id === payload.id)
    const index3 = state.vehicleSchedules.findIndex(o => o.id === payload.id)
    if (index1 >= 0) Vue.set(state.studentSchedules, index1, payload)
    if (index2 >= 0) Vue.set(state.employeeSchedules, index2, payload)
    if (index3 >= 0) Vue.set(state.vehicleSchedules, index3, payload)

    state.selectedDays.map(e => {
      const idx = e.studentSchedule.findIndex(o => o.id === payload.id)
      if (idx >= 0) Vue.set(e.studentSchedule, idx, payload)

      const idx2 = e.employeeSchedule.findIndex(o => o.id === payload.id)
      if (idx2 >= 0) Vue.set(e.employeeSchedule, idx2, payload)

      const idx3 = e.vehicleSchedule.findIndex(o => o.id === payload.id)
      if (idx3 >= 0) Vue.set(e.vehicleSchedule, idx3, payload)
    })
  },
  UPDATE_SCHEDULES_BY_FILTER (state, payload) {
    const schedules = state.schedulesByFilter
    state.schedulesByFilter = payload.map(e => ({
      ...e,
      ...schedules.find(s => s.id === e.id)
    }))
  },

  //AQUI NAO UTILIZO O RETORNO DA API, NESTE CASO FAÇO A MUDANÇA ANTES DE ENVIAR PARA A API
  UPDATE_MASSIVE_SCHEDULES (state, payload) {
    payload.schedules.map(a => {
      const index1 = state.studentSchedules.findIndex(o => o.id === a.id)
      const index2 = state.employeeSchedules.findIndex(o => o.id === a.id)
      const index3 = state.vehicleSchedules.findIndex(o => o.id === a.id)
      if (index1 >= 0) Vue.set(state.studentSchedules, index1, Object.assign({}, a, payload.changes))
      if (index2 >= 0) Vue.set(state.employeeSchedules, index2, Object.assign({}, a, payload.changes))
      if (index3 >= 0) Vue.set(state.vehicleSchedules, index3, Object.assign({}, a, payload.changes))

      state.selectedDays.map(e => {
        const idx =  e.studentSchedule.findIndex(o => (o.id && o.id === a.id) || (!o.id && o.start_time === a.start_time && o.start_date === a.start_date))
        const idx1 = e.employeeSchedule.findIndex(o => (o.id && o.id === a.id) || (!o.id && o.start_time === a.start_time && o.start_date === a.start_date))
        const idx2 = e.vehicleSchedule.findIndex(o => (o.id && o.id === a.id) || (!o.id && o.start_time === a.start_time && o.start_date === a.start_date))
        if (idx >= 0)  Vue.set(e.studentSchedule, idx, Object.assign({}, a, payload.changes))
        if (idx1 >= 0) Vue.set(e.employeeSchedule, idx, Object.assign({}, a, payload.changes))
        if (idx2 >= 0) Vue.set(e.vehicleSchedule, idx, Object.assign({}, a, payload.changes))
      })
    })
  },

  SET_SELECTED_DAYS (state, payload) {
    state.selectedDays = payload.sort((a, b) => {               //ORDENA AS DATAS
      return new Date(a.date) - new Date(b.date)
    })
  },

  ADD_STUDENT_SCHEDULE (state, payload) {
    payload.student.schedules.push(payload.response)
    // CRIAR ORDEM DAS AGENDAS POR CATEGORIA
    const studentSchedulesOrdered = payload.student.schedules.sort((a, b) => {
      return new Date(`${a.start_date.slice(0, 10)}T${a.start_time}`) - new Date(`${b.start_date.slice(0, 10)}T${b.start_time}`)
    })

    const result = Object.values(_.groupBy(studentSchedulesOrdered, 'category'))
    result.map(a => {
      a.map((b, idx) => {
        b.order = idx + 1
      })
    })
    state.studentSchedules = _.flatMap(result)
  },

  ADD_EMPLOYEE_SCHEDULE (state, payload) {
    state.employeeSchedules.push(payload)
  },

  UPDATE_PERIOD (state, payload) {
    state.periods = payload
  },

  SET_STUDENT_SCHEDULES (state, payload) {
    state.studentSchedules = payload //precisa ser assim para manter a reatividade
  },
  SET_EMPLOYEE_SCHEDULES (state, payload) {
    state.employeeSchedules = payload //precisa ser assim para manter a reatividade
  },
  SET_VEHICLE_SCHEDULES (state, payload) {
    state.vehicleSchedules = payload //precisa ser assim para manter a reatividade
  },
  SET_HOLIDAY_SCHEDULES (state, payload) {
    state.holidaySchedules = payload //precisa ser assim para manter a reatividade
  },
  SET_SCHEDULES_BY_FILTER (state, payload) {
    state.schedulesByFilter = payload //precisa ser assim para manter a reatividade
  },

  // COLOCA OS DADOS DOS AGENDAMENTOS EM SELECEDDAYS PARA PREENCHER A TABELA DE AGENDAS
  FILL_SELECTED_DAYS (state) {
    // console.time('FILL_SELECTED_DAYS')

    const selectedDays = JSON.parse(JSON.stringify(state.selectedDays))

    selectedDays.map(selectedDay => {
      selectedDay.studentSchedule.splice(0)   // ESVAZIO AS AGENDAS EM TODOS OS DIAS PREENCHIDOS
      selectedDay.employeeSchedule.splice(0)  // ESVAZIO AS AGENDAS EM TODOS OS DIAS PREENCHIDOS
      selectedDay.vehicleSchedule.splice(0)   // ESVAZIO AS AGENDAS EM TODOS OS DIAS PREENCHIDOS
      selectedDay.holidaySchedule.splice(0)   // ESVAZIO AS AGENDAS EM TODOS OS DIAS PREENCHIDOS

      if (state.studentSchedules.length) {
        state.studentSchedules.map(schedule => {      // PREENCHO OS DIAS COM AS AGENDAS RECEBIDAS NOS DIAS CORRESPONDENTES
          if (selectedDay.id === schedule.start_date.slice(0, 10)) {
            selectedDay.studentSchedule.push(schedule)
          }
        })
      }
      if (state.employeeSchedules.length) {
        state.employeeSchedules.map(schedule => {     // PREENCHO OS DIAS COM AS AGENDAS RECEBIDAS NOS DIAS CORRESPONDENTES
          if (selectedDay.id === schedule.start_date.slice(0, 10)) {
            selectedDay.employeeSchedule.push(schedule)
          }
        })
      }
      if (state.vehicleSchedules.length) {
        state.vehicleSchedules.map(schedule => {     // PREENCHO OS DIAS COM AS AGENDAS RECEBIDAS NOS DIAS CORRESPONDENTES
          if (selectedDay.id === schedule.start_date.slice(0, 10)) {
            selectedDay.vehicleSchedule.push(schedule)
          }
        })
      }
      if (state.holidaySchedules.length) {
        state.holidaySchedules.map(schedule => {     // PREENCHO OS DIAS COM AS AGENDAS RECEBIDAS NOS DIAS CORRESPONDENTES
          if (selectedDay.id === schedule.date.slice(0, 10)) {
            selectedDay.holidaySchedule.push(schedule)
          }
        })
      }
    })

    state.selectedDays = selectedDays

    // console.timeEnd('FILL_SELECTED_DAYS')
  },

  //EXCLUI DO ARRAY
  DELETE (state, payload) {
    const index1 = state.studentSchedules.findIndex(o => o.id === payload.id)
    const index2 = state.employeeSchedules.findIndex(o => o.id === payload.id)
    const index3 = state.vehicleSchedules.findIndex(o => o.id === payload.id)
    if (index1 >= 0) state.studentSchedules.splice(index1, 1)   //precisa ser assim para manter a reatividade
    if (index2 >= 0) state.employeeSchedules.splice(index2, 1)  //precisa ser assim para manter a reatividade
    if (index3 >= 0) state.vehicleSchedules.splice(index3, 1)   //precisa ser assim para manter a reatividade

    let studentSchedulesOrdered = []

    if (payload.student && payload.student.schedules) {
      const idx = payload.student.schedules.findIndex(o => o.id === payload.id)
      if (idx >= 0) payload.student.schedules.splice(idx, 1) //precisa ser assim para manter a reatividade
      // CRIAR ORDEM DAS AGENDAS POR CATEGORIA
      studentSchedulesOrdered = payload.student.schedules.sort((a, b) => {
        return new Date(`${a.start_date.slice(0, 10)}T${a.start_time}`) - new Date(`${b.start_date.slice(0, 10)}T${b.start_time}`)
      })

      const result = Object.values(_.groupBy(studentSchedulesOrdered, 'category'))
      result.map(a => a.map((b, idx) => { b.order = idx + 1 }))
      studentSchedulesOrdered = _.flatMap(result)
    }

    state.selectedDays.map(e => {
      const idx = e.studentSchedule.findIndex(o => o.id === payload.id)
      if (idx >= 0) e.studentSchedule.splice(idx, 1) //precisa ser assim para manter a reatividade
      e.studentSchedule.map(s => {
        const id = studentSchedulesOrdered.findIndex(o => o.id === s.id)
        if (id >= 0) s.order =  studentSchedulesOrdered[id].order
      })

      const idx2 = e.employeeSchedule.findIndex(o => o.id === payload.id)
      if (idx2 >= 0) e.employeeSchedule.splice(idx2, 1) //precisa ser assim para manter a reatividade

      const idx3 = e.vehicleSchedule.findIndex(o => o.id === payload.id)
      if (idx3 >= 0) e.vehicleSchedule.splice(idx3, 1) //precisa ser assim para manter a reatividade
    })

    if (state.schedule && state.schedule.id === payload.id) {
      state.schedule = Object.assign({}, {}) //precisa ser assim para manter a reatividade
    }
  },

  // MÓDULO DE ALUNOS
  SET_STUDENT_LIST_SCHEDULES (state, payload) {
    state.studentListSchedules = payload
  },
  DELETE_STUDENT_LIST_SCHEDULES (state, payload) {
    const idx = state.studentListSchedules.data.findIndex(o => o.id === payload)
    if (idx >= 0) {
      state.studentListSchedules.data.splice(idx, 1) //precisa ser assim para manter a reatividade
      state.studentListSchedules.total = state.studentListSchedules.total - 1
    }
  },
  ADD_STUDENT_LIST_SCHEDULES (state, payload) {
    state.studentListSchedules.data.push(payload)
    state.studentListSchedules.total = state.studentListSchedules.total + 1
  },
  UPDATE_STUDENT_LIST_SCHEDULES (state, payload) {
    const idx = state.studentListSchedules.data.findIndex(o => o.id === payload.id)
    if (idx >= 0) Vue.set(state.studentListSchedules.data, idx, payload)
  },

  //veículos
  SET_VEHICLE_SCHEDULES_GRID (state, payload) {
    state.vehicleSchedulesGrid = payload
  }

}
