export default {
  schedule: {},
  selectedDays: [],
  periods: [0, 1, 2], // 1 MATUTINO 2 VESPERTINO 3 NOTURNO
  studentSchedules: [],
  employeeSchedules: [],
  vehicleSchedules: [],
  holidaySchedules: [],
  schedulesByFilter: [],

  vehicleSchedulesGrid: {
    data: [],
    total: 0
  },

  //MÓDULO DE ALUNOS
  studentListSchedules: []
}
