import axios from '@/axios.js'

export default {
  block ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/schedule/block`, payload)
        .then((response) => {
          commit('ADD_EMPLOYEE_SCHEDULE', response.data)    // AQUI BLOQUEIO SOMENTE INSTRUTOR
          commit('FILL_SELECTED_DAYS')
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  syncSelectedSchedulesIbio ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/schedule/syncSelectedSchedulesIbio`, payload)
        .then((response) => {
          response.data.forEach((schedule) => {
            commit('UPDATE_SCHEDULE', schedule)
          })
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  onlySyncSelectedSchedulesIbio ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/schedule/syncSelectedSchedulesIbio`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateSchedulesChanged ({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/schedule/changeSchedules`, payload)
        .then((response) => {
          commit('UPDATE_SCHEDULES_BY_FILTER', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateMany (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/schedule/updateMany`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  store ({ commit, rootGetters }, payload) {
    const student = rootGetters['studentManagement/getStudent']
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/schedule`, payload)
        .then((response) => {
          commit('ADD_STUDENT_SCHEDULE', {response: response.data[0], student})    // ESTOU RETORNANDO UM ARRAY DE SCHEDULES
          commit('FILL_SELECTED_DAYS')
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  update ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${process.env.VUE_APP_API_URL}/schedule/${payload.id}`, payload)
        .then((response) => {
          commit('UPDATE_SCHEDULE', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchFilter ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/schedule/get`, payload)
        .then((response) => {
          commit('SET_STUDENT_SCHEDULES', response.data.studentSchedule)
          commit('SET_EMPLOYEE_SCHEDULES', response.data.employeeSchedule)
          commit('SET_VEHICLE_SCHEDULES', response.data.vehicleSchedule)
          commit('SET_HOLIDAY_SCHEDULES', response.data.holidaySchedules)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchSchedulesByFilter ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/schedule/getByFilter`, payload)
        .then((response) => {
          commit('SET_SCHEDULES_BY_FILTER', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  delete ({ commit, rootGetters }, id) {
    const student = rootGetters['studentManagement/getStudent']
    return new Promise((resolve, reject) => {
      axios.delete(`${process.env.VUE_APP_API_URL}/schedule/${id}`)
        .then((response) => {
          commit('DELETE', {id, student})
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  deleteMany ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/schedule/deleteMany`, payload)
        .then((response) => {
          payload.forEach(id => {
            commit('DELETE', id)
          })
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchVehicleGrid ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/schedule/vehicle`, payload)
        .then((response) => {
          commit('SET_VEHICLE_SCHEDULES_GRID', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },


  // MÓDULO DE ALUNOS
  fetchStudentSchedules ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/schedule/student`, payload)
        .then((response) => {
          commit('SET_STUDENT_LIST_SCHEDULES', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
